import React, { useState, useEffect } from "react"
import axios from "axios"

import { getSection } from "../helpers"
import ImgContact from "../images/img-contact.png"

const title = "Сontact us"
const sub = (
  <p>
    Enter your <b>phone number</b> or <b>email</b> to get in touch with us.
  </p>
)

const LContact = () => {
  const [isSent, setIsSent] = useState(false)
  const [inputError, setInputError] = useState("")

  const sendToDiscord = async () => {
    const formvalue = document.getElementById("inputdiscord").value
    // const regexpEmail = /^\w{5,16}\@\w{5,16}\.com$/
    // const regexpMobilo = /^\d{3}[ -.]{0,1}\d{3}[ -.]{0,1}\d{4}$/
    if (!formvalue || typeof formvalue !== "string" || formvalue.length === 0) {
      setInputError("Please enter a valid email address or phone number")
      return
    } else {
      setInputError("")
      try {
        const res = await axios({
          method: "POST",
          url: "/.netlify/functions/sendToDiscord",
          data: {
            userData: formvalue,
          },
        })
        if (!res.data.success) {
          setInputError(res.data.error)
        } else {
          setInputError("")
        }
        document.getElementById("inputdiscord").value = ""
        setIsSent(true)
      } catch (e) {
        console.log("sendToDiscord Error :>> ", e)
      }
    }
  }

  return getSection(
    <img src={ImgContact} alt="contact" />,
    isSent ? (
      <>
        <h2>Thank You!</h2>
        <p>Thank you! We will get in contact with you soon!</p>
      </>
    ) : (
      <>
        <h2>{title}</h2>
        {sub}
        <input
          className="inputdiscord"
          id="inputdiscord"
          type="text"
          placeholder="e.g. john@gmail.com"
          onChange={() => setInputError("")}
        />
        {inputError && <p>{inputError}</p>}
        <div className="button button-div" onClick={sendToDiscord}>
          send
        </div>
      </>
    ),
    "contact"
  )
}

export default LContact
