import React from "react"

import { getSection, getTitleSubText } from "../helpers"
import { useIsDesktop } from "../helpers/hooks"
import ImgMap from "../images/img-map.png"

const title = "project map"
const sub = "what we do"
const text = [
  "UKRENEVO, as a company, is here to help you build a better future, to do it right while avoiding unnecessary and costly mistakes. With over 100 projects all over Turkey and 15 in Ukraine, we have tremendous institutional experience in designing, building, managing, and maintaining green energy projects of any scope and scale.",
  "We are committed to providing our clients with the best technology and expertise to create a sustainable infrastructure that is safe, reliable, efficient, and profitable. We employ some of the best engineers in the field and have long-standing relationships with market-leading providers of related goods and services.",
  "We take pride in every project because we know that every one of them is changing the world for the better.",
]

const LMap = () => {
  const isDesktop = useIsDesktop()

  const leftBlock = () => (
    <>
      {!isDesktop && <h2>PROJECT MAP</h2>}
      <img src={ImgMap} alt="map" />
    </>
  )

  return getSection(
    leftBlock(),
    getTitleSubText(isDesktop ? title : null, sub, text),
    "map"
  )
}

export default LMap
