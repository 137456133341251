import React from "react"
import { Link } from "gatsby"

const LButtonFloat = () => (
  <div id="floatbutton" className="float-button-container">
    <Link
      className="button"
      to="https://form.jotform.com/212902604231039"
      target="_blank"
    >
      Calculate
    </Link>
  </div>
)

export default LButtonFloat
