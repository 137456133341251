import React from "react"

import { getSection, getTitleSubText } from "../helpers"
import ImgAbout from "../images/img-about-us.png"

const title = "about us"
const sub = "ukrenevo"
const text = [
  "UKRENEVO is a subsidiary of Renevo Energy - a trusted name in the renewable energy market. We provide a full range of services: assessment, planning, procurement, construction, management, and maintenance.",
  "With over 15 years on the market and over 100 successful projects that total well over 1000MW, we have ample organizational and engineering experience to handle a green energy project of any scale and complexity.",
  "UKRENEVO offers cutting-edge end-to-end solutions for projects - big and small - budget residential installations, large utility power plants, and anything in-between.",
  "Look around and find out more!",
]

const LAbout = () => {
  return getSection(
    getTitleSubText(title, sub, text),
    <img src={ImgAbout} alt="" />,
    "about"
  )
}

export default LAbout
