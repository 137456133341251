import React, { useState, useCallback } from "react"
import ReactPlayer from "react-player"
import { useIsDesktop } from "../helpers/hooks"

const quasyLinks = [
  { text: "solar", link: "#" },
  { text: "WIND power", link: "#" },
  { text: "HYDROELECTRIC  ENERGY", link: "#" },
  { text: "Battery Storage", link: "#" },
]

const LHero = () => {
  const isDesktop = useIsDesktop(991)

  const [height, setHeight] = useState(null)
  const [width, setWidth] = useState(null)
  const div = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height)
      setWidth(node.getBoundingClientRect().width)
    }
  }, [])

  return (
    <section className="hero" ref={div}>
      {isDesktop ? (
        <div className="video-player-wrapper">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=B-_BWLQVqV0"
            playing={true}
            controls={false}
            loop={true}
            muted={true}
            width={"100%"}
            height={"100%"}
            className="video-player"
            config={{
              youtube: { playerVars: { disablekb: 1, modestbranding: 1 } },
            }}
          />
          <div className="video-filter" />
        </div>
      ) : (
        <>
          <div className="hero-background" />
          <div className="video-filter" />
        </>
      )}

      <div className="video-overlay">
        <h1>
          Renewable Energy
          <br />
          for a Sustainable World
        </h1>
        <h3>ukrenevo</h3>
        <div className="quazy-links">
          {quasyLinks.map((link, l) => (
            <span key={l}>{link.text}</span>
          ))}
        </div>
      </div>
    </section>
  )
}

export default LHero
