import React from "react"
import Slider from "react-slick"
import { getSection } from "../helpers"
import { useIsDesktop } from "../helpers/hooks"

import img01 from "../images/icons/partners01.png"
import img02 from "../images/icons/partners02.svg"
import img03 from "../images/icons/partners03.svg"
import img04 from "../images/icons/hydrotu.svg"
import img05 from "../images/icons/partners05.svg"
import img06 from "../images/icons/partners06.svg"

const images = [img01, img02, img03, img04, img05, img06]

const contentParts = [
  {
    title: "solution partners",
    text: "Tried and tested solutions that we utilize in our project.",
  },
  {
    title: "company partners",
    text:
      "Companies with which we are happy to build a better future side by side!",
  },
  {
    title: "Monitoring partners",
    text:
      "Choice partners, that provide some of the best Operations and Maintenance services on the market.",
  },
]

const settings = {
  dots: false,
  autoplay: true,
  autoplaySpeed: 2750,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  className: "partners-slider",
  slidesToShow: 2,
  arrows: false,
}

const LPartners = () => {
  const isDesktop = useIsDesktop()

  const rightBlock = (
    <>
      {isDesktop && <h2>partners</h2>}
      <p>
        While we are open to cooperating with companies and using products to
        our client’s preference, there are a select few that we prefer and
        wholeheartedly endorse!
      </p>
      {contentParts.map((item, i) => (
        <div key={i}>
          <h3>{item.title}</h3>
          <p>{item.text}</p>
        </div>
      ))}
    </>
  )

  const leftBlock = (
    <>
      {!isDesktop && <h2>partners</h2>}
      {isDesktop ? (
        <div className="partners-icons-container">
          {images.map((img, i) => (
            <img key={i} src={img} alt="" />
          ))}
        </div>
      ) : (
        <Slider {...settings}>
          {images.map((img, i) => (
            <div className="slide-item" key={i}>
              <img src={img} alt="" />
            </div>
          ))}
        </Slider>
      )}
    </>
  )

  return getSection(leftBlock, rightBlock, "partners")
}

export default LPartners
