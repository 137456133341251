import React from "react"

export const getSection = (left, right, addClass = "") => {
  return (
    <section id={addClass} className={`section2items ${addClass}`}>
      <div className="inner">
        <div className="block block-left">{left}</div>
        <div className="block block-right">{right}</div>
      </div>
    </section>
  )
}

export const getTitleSubText = (title, sub, text, slider) => {
  return (
    <div className="title-sub-text">
      {title && <h2>{title}</h2>}
      {sub && <h3>{sub}</h3>}
      {text && text.map((item, i) => <p key={i}>{item}</p>)}
      {slider}
    </div>
  )
}
