import React from "react"

import { getSection, getTitleSubText } from "../helpers"
import ImgClients from "../images/img-clients.svg"
import img01 from "../images/icons/clients01.svg"
import img02 from "../images/icons/clients02.svg"
import img03 from "../images/icons/clients03.svg"
import img04 from "../images/icons/clients04.svg"
import img05 from "../images/icons/clients05.svg"
import img06 from "../images/icons/clients06.svg"

import Slider from "react-slick"

const settings = {
  dots: false,
  autoplay: true,
  autoplaySpeed: 3250,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  className: "clients-slider",
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        // arrows: false,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
        arrows: false,
      },
    },
  ],
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className="clients-next"
      className={`${className} clients-next`}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className="clients-prev"
      className={`${className} clients-prev`}
      onClick={onClick}
    />
  )
}

const images = [img01, img02, img03, img04, img05, img06]

const slider = (
  <Slider {...settings}>
    {images.map((img, i) => (
      <div className="slide-item" key={i}>
        <img src={img} alt="" />
      </div>
    ))}
  </Slider>
)

const title = "clients"
const sub = "Business"
const text = [
  "In our approach to enterprise clients, we always prioritize their specific needs and requirements. Our every decision has to be made with our client’s goals and bottom line in mind. Over the years, we had the fortune to work with many wonderful, forward-thinking, and goal-oriented companies. Some of them we can list here.",
]

const LClients = () => {
  return getSection(
    getTitleSubText(title, sub, text, slider),
    <img src={ImgClients} alt="" />,
    "clients"
  )
}

export default LClients
