import * as React from "react"
import Layout from "../components/layout/Layout"
import LHero from "../components/LHero"
import LAbout from "../components/LAbout"
import LClients from "../components/LClients"
import LPartners from "../components/LPartners"
import LMap from "../components/LMap"
import LProjects from "../components/LProjects"
import LContact from "../components/LContact"
import LButtonFloat from "../components/LButtonFloat"
import LMainBackgroundLines from "../components/LMainBackgroundLines"

import "../scss/main.scss"

console.log("isDev")

const IndexPage = () => (
  <>
    <LButtonFloat />
    <Layout>
      <LHero />
      <LMainBackgroundLines />
      <LAbout id="about" name="about" />
      <LClients id="clients" name="clients" />
      <LPartners id="partners" name="partners" />
      <LMap id="map" name="map" />
      <LProjects id="projects" name="projects" />
      <LContact id="contact" name="contact" />
    </Layout>
  </>
)

export default IndexPage
