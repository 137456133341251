import React, { useState, useEffect } from "react"

import { getSection, getTitleSubText } from "../helpers"
import { useIsDesktop } from "../helpers/hooks"

import ImgThumb01 from "../images/img-projects-01-thumb.png"
import ImgThumb02 from "../images/img-projects-02-thumb.png"
import ImgThumb03 from "../images/img-projects-03-thumb.png"
import Img01 from "../images/img-projects-01.png"
import Img02 from "../images/img-projects-01.png"
import Img03 from "../images/img-projects-01.png"

const content = [
  {
    title: "Sutkivtsi 19MW",
    text: [
      "In 2020, UKRENEVO built more than 10 solar-generated facilities in Ukraine. Ukrenevo is a subsidiary of the Turkish company Renevo Energy, which has many years of experience in the implementation of ground and roof solar generation projects.",
    ],
    img: Img01,
    thumbTitle: "Sutkivtsi 19MW",
    thumb: ImgThumb01,
  },
  {
    title: "Balıkesir - Savaştepe & Bandırma: 7 x 1.5 MW WPP 2",
    text: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris mattis erat id nisi auctor ullamcorper. In pellentesque purus eget ullamcorper pretium. Suspendisse iaculis mi in dapibus dapibus. Quisque ornare vitae orci et ornare. Nulla enim ante, condimentum quis dui a, fermentum malesuada felis. Suspendisse potenti. Vivamus eu feugiat magna, vitae molestie leo. Vivamus fermentum leo libero, ut blandit purus placerat non. Fusce nec dolor id augue condimentum posuere vel eget leo. Ut viverra quam vitae nibh tincidunt pharetra. Cras blandit eros non diam volutpat, in feugiat est tincidunt. Nam eros eros, auctor non est a, accumsan posuere erat.",
    ],
    img: Img02,
    thumbTitle: "Erzurum - İspir: 3 MW SPP",
    thumb: ImgThumb02,
  },
  {
    title: "Balıkesir - Savaştepe & Bandırma: 7 x 1.5 MW WPP 3",
    text: [
      "Sed hendrerit blandit eros at pharetra. Proin est dui, finibus vel rhoncus tincidunt, euismod non mauris. Proin semper, ante non tempor pretium, ligula ligula dapibus arcu, vel posuere dui diam non arcu. Vestibulum neque neque, feugiat eu enim ac, ultrices molestie ante. Integer vitae fermentum nibh. Vivamus eu vulputate leo.",
      "Nam ac tellus at nisi tincidunt pharetra. Integer rhoncus quam quam. Quisque egestas elementum sem a tincidunt. Phasellus ut nisi fermentum, molestie ipsum et, luctus nunc. Praesent suscipit, lectus nec euismod viverra, magna tortor facilisis ligula, in gravida arcu neque eget diam.",
    ],
    img: Img03,
    thumbTitle: "Erzurum - İspir: 3 MW SPP",
    thumb: ImgThumb03,
  },
]

const LProjects = () => {
  const [title, setTitle] = useState(content[0].title)
  const [text, setText] = useState(content[0].text)
  const [img, setImg] = useState(content[0].img)

  const isDesktop = useIsDesktop()

  const handleClick = i => {
    setTitle(content[i].title)
    setText(content[i].text)
    setImg(content[i].img)
    return null
  }
  return (
    <div className="project-viewer">
      {getSection(
        getTitleSubText(isDesktop ? "our projects" : null, title, text),
        <img src={img} alt="" />,
        "projects"
      )}
      <div className="viewer">
        <div className="inner">
          {!isDesktop && <h2>our projects</h2>}
          {content.map((item, i) => (
            <img
              key={i}
              src={item.thumb}
              // onClick={() => handleClick(i)} // TODO: just because we will show just one project at this moment
              alt="thumb"
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default LProjects
